import imageDesings from "../images/designs/*.png";

var canvas = $("#canvas"),
    context = canvas.get(0).getContext("2d"),
    $result = $('#result'),
    imgselected = false;

//no files when click on add button
$('#btnCrop').click(function() {
    if (document.getElementById("file").files.length == 0 && imgselected == false) {
        alert("No image selected");
    }
});

$('#file').on('change', function() {
    if (this.files && this.files[0]) {
        if (this.files[0].type.match(/^image\//)) {
            var reader = new FileReader();
            reader.onload = function(evt) {
                var img = new Image();
                img.onload = function() {
                    canvas.cropper('destroy');

                    context.canvas.height = img.height;
                    context.canvas.width = img.width;
                    context.drawImage(img, 0, 0);
                    imgselected = true;

                    var cropper = canvas.cropper({
                        aspectRatio: 1 / 1,
                        viewMode: 1,
                        dragMode: 'move',
                        guides: false,
                        center: false,
                        checkOrientation: true,
                        cropBoxMovable: false,
                        cropBoxResizable: false,
                        modal: false,
                        rotatable: true,
                        scalable: true,
                        autocrop: true,
                        autoCropArea: 0.65,
                        background: false,
                        cropBoxResizable: false,
                        minCanvasWidth: 132,
                        minCanvasHeight: 132,
                        minCropBoxWidth: 132,
                        minCropBoxHeight: 132,
                        built: function() {
                            $(this).cropper('setCropBoxData', {
                                width: 132,
                                height: 132
                            });
                        }
                    });


                    $('#btnRestore').click(function() {
                        canvas.cropper('reset');
                        $result.empty();
                    });
                };
                img.src = evt.target.result;
            };
            reader.readAsDataURL(this.files[0]);
        } else {
            alert("Invalid file type! Please select an image file.");
        }
    } else {
        alert('No image selected.');
    }
});

$('#btnCrop').click(function() {
    // Get a string base 64 data url
    // Create new tag with img
    if (imgselected) {
        var croppedImageDataURL = canvas.cropper('getCroppedCanvas').toDataURL("image/png");
        var newDiv = $('#christmas-tag-preview').first().clone().appendTo("#container");
        $(newDiv).find('.cropper-container').remove();
        $(newDiv).find('img').attr('src', croppedImageDataURL);

    }

});

//duplicate item
$(document).on('click', '.duplicate', function() {
    $(this).parent('div').clone().insertAfter($(this).parent());
});

//remove item
$(document).on('click', '.remove', function() {
    $(this).parent().fadeOut("300", function() {
        $(this).remove();
    });
});

//remove responsive hiding
$(document).on('click', '#useanyway', function() {
    $("div").removeClass("hidden-desktop hidden-mobile");
    $("div").remove(".responsive-alert");
});

//print button with Firefox alert
$(document).on('click', '#btnPrint', function() {
    // if (navigator.userAgent.indexOf("Firefox") != -1) { alert('Please turn off "Print headers and footers" in the following print dialog'); }
    window.print();
});

//change design
$(document).ready(function() {
    var i = 2;

    function updateDesign() {
        $('#christmas-tag-preview').css('background-image', 'url(' + imageDesings["bg_"+i] +')');
        
        switch (i) {
            case 1: $('#christmas-tag-preview').css('color', '#F8715B'); break;
            case 2: $('#christmas-tag-preview').css('color', 'black'); break;
            case 3: $('#christmas-tag-preview').css('color', 'white'); break;
            case 4: $('#christmas-tag-preview').css('color', '#00C7FF'); break;
            case 5: $('#christmas-tag-preview').css('color', 'white'); break;
            case 6: $('#christmas-tag-preview').css('color', 'black'); break;
            case 7: $('#christmas-tag-preview').css('color', 'black'); break;
            case 8: $('#christmas-tag-preview').css('color', 'white'); break;
            case 9: $('#christmas-tag-preview').css('color', 'white'); break;
            case 10: $('#christmas-tag-preview').css('color', '#774209'); break;
        }
    }

    // Call updateDesign during the initial page load
    updateDesign();

    $(".design-left, .design-right").click(function() {
        if ($(this).hasClass('design-left')) {
            i--;
            if (i < 1) { i = 10; };
        } else {
            i++;
            if (i > 10) { i = 1; };
        }

        updateDesign();
    });
});
